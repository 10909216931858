<script>
  import { setContext } from 'svelte';
  import { writable } from 'svelte/store';
  import { Theme } from 'wind-svelte';
  import ExternalStyles from '../components/ExternalStyles.svelte';
  import Vehicles from './Vehicles.svelte';

  // config
  export let grid = false;
  export let appUrl = '';
  export let apiUrl = '';
  export let compactResults = false;
  export let previewFit = '';
  export let smCols = 1;
  export let mdCols = 1;
  export let lgCols = 1;
  export let xlCols = 1;
  export let xxlCols = 1;

  // filters
  export let bodyTypes = null;
  export let brands = null;
  export let colors = null;
  export let minKms = null;
  export let maxKms = null;
  export let locations = null;
  export let models = null;
  export let minPrice = null;
  export let maxPrice = null;
  export let search = null;
  export let states = null;
  export let transmissions = null;
  export let years = null;
  export let sellers = null;

  // others
  export let styles = [];
  export let mainStyles = '';
  export let webComponentContainer = null;
  export let dispatchEvent = null;
  export let pricingColor = 'rgb(0, 0, 0)';

  setContext('webComponent', { webComponentContainer, dispatchEvent });

  const vehicleContext = writable({
    ready: false,
    sellers: [],
    vehicles: [],
    vehiclePages: 0,
    config: {
      grid: null,
      appUrl: null,
      apiUrl: null,
      compactResults: null,
      previewFit: null,
    },
    values: {
      bodyTypes: null,
      brands: null,
      colors: null,
      minKms: null,
      maxKms: null,
      locations: null,
      models: null,
      minPrice: null,
      maxPrice: null,
      search: null,
      sellers: null,
      states: null,
      transmissions: null,
      years: null,
    },
  });

  setContext('vehicle', vehicleContext);

  $: $vehicleContext.config.grid = grid;
  $: $vehicleContext.config.appUrl = appUrl;
  $: $vehicleContext.config.apiUrl = apiUrl;
  $: $vehicleContext.config.compactResults = compactResults;
  $: $vehicleContext.config.previewFit = previewFit;
  $: $vehicleContext.config.smCols = smCols;
  $: $vehicleContext.config.mdCols = mdCols;
  $: $vehicleContext.config.lgCols = lgCols;
  $: $vehicleContext.config.xlCols = xlCols;
  $: $vehicleContext.config.xxlCols = xxlCols;

  $: $vehicleContext.values.bodyTypes = bodyTypes;
  $: $vehicleContext.values.brands = brands;
  $: $vehicleContext.values.colors = colors;
  $: $vehicleContext.values.minKms = minKms;
  $: $vehicleContext.values.maxKms = maxKms;
  $: $vehicleContext.values.locations = locations;
  $: $vehicleContext.values.models = models;
  $: $vehicleContext.values.minPrice = minPrice;
  $: $vehicleContext.values.maxPrice = maxPrice;
  $: $vehicleContext.values.search = search;
  $: $vehicleContext.values.sellers = sellers;
  $: $vehicleContext.values.states = states;
  $: $vehicleContext.values.transmissions = transmissions;
  $: $vehicleContext.values.years = years;

  let loaded = false;
  let stylesLoaded = false;
</script>

<link rel="stylesheet" href={mainStyles} on:load={() => loaded = true}>
<ExternalStyles styles={styles} bind:value={stylesLoaded} />

<Theme colors={{ pricing: pricingColor }}>
  {#if loaded && stylesLoaded}
    <Vehicles />
  {/if}
</Theme>
