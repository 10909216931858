<script>
  import { Select, HistogramSlider, RangeSlider } from 'wind-svelte';
  import createChecker from '../utils/createChecker';
  import formatNumber from '../utils/formatNumber';

  export let type = null;
  export let from = null;
  export let to = null;
  export let ranges = [];
  export let label = '';

  // #region slider + histogram
  let sliderValue = [0, 0];
  let sliderSelection = [];

  const updateSliderValueChecker = createChecker(() => {
    sliderValue = [from, to];
  }, from, to);
  $: updateSliderValueChecker(from, to);

  const updateSliderRangesChecker = createChecker(() => {
    from = sliderValue[0];
    to = sliderValue[1];
  }, ...sliderValue);
  $: updateSliderRangesChecker(...sliderValue);

  $: first = ranges[0];
  $: last = ranges[ranges.length - 1];
  $: min = first?.from;
  $: max = last?.to;
  // #endregion

  // #region select
  let selectValue = null;
  const updateSelectValueChecker = createChecker(() => {
    selectValue = ranges.find((range) => range.from === from && range.to === to)
      ? `${from}-${to}`
      : null;
  }, from, to);
  $: updateSelectValueChecker(from, to);

  function identify(option) {
    return `${option.from}-${option.to}`;
  }

  function getText(option) {
    const displayFrom = formatNumber(option.from);
    const displayTo = formatNumber(option.to);
    const displayCount = formatNumber(option.count);

    return `${displayFrom} - ${displayTo} (${displayCount} vehiculos)`;
  }

  function onChangeSelect({ detail }) {
    if (detail) {
      from = detail.from;
      to = detail.to;
    } else {
      from = null;
      to = null;
    }
  }
  // #endregion
</script>

{#if type === 'slider'}
  <div>
    <RangeSlider
      {min}
      {max}
      fullWidth
      bind:value={sliderValue}
      bind:selection={sliderSelection}
    />
    <div class="flex justify-between">
      <slot name="selection-begin" value={sliderSelection[0]} />
      <slot name="selection-end" value={sliderSelection[1]} />
    </div>
  </div>
{:else if type === 'histogram'}
  <div>
    <HistogramSlider
      ranges={[
        first?.from,
        ...ranges.map((range) => range.to),
      ]}
      counters={ranges.map((range) => range.count)}
      bind:value={sliderValue}
      bind:selection={sliderSelection}
    />
    <div class="flex justify-between">
      <slot name="selection-begin" value={sliderSelection[0]} />
      <slot name="selection-end" value={sliderSelection[1]} />
    </div>
  </div>
{:else if type === 'select'}
  <div>
    <Select
      {identify}
      {getText}
      {label}
      fullWidth
      clearable
      value={selectValue}
      options={ranges}
      on:change={onChangeSelect}
    />
  </div>
{/if}
