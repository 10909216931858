import SearchComponent from './SearchComponent/SearchComponent.svelte';
import VehiclesComponent from './VehiclesComponent/VehiclesComponent.svelte';
import defineCustomComponent from './defineWebComponent/defineWebComponent';

const json = (defaultValue) => (raw) => (raw ? JSON.parse(raw) : null) || defaultValue;
const list = (separator = ' ', defaultValue = []) => (raw) => {
  if (typeof raw === 'string') {
    return raw.split(separator).filter(Boolean);
  }

  return defaultValue;
};
const oneOf = (values, defaultValue = null) => (raw) => {
  if (values.includes(raw)) return raw;

  return defaultValue;
};
const text = (defaultValue) => (raw) => (typeof raw === 'string' ? raw : null) || defaultValue;
const boolean = (defaultValue = false) => (raw) => {
  if (raw === undefined) return defaultValue;
  if (raw === '' || raw === 'true') return true;
  return false;
};
const number = (defaultValue = 0) => (raw) => {
  if (raw === undefined) return defaultValue;
  return Number(raw) || defaultValue;
}

defineCustomComponent({
  component: SearchComponent,
  tagName: 'intelimotor-search',
  props: {
    'styles': list(/\s+/, []),
    'app-url': text(),
    'api-url': text(),
    'controls': json([]),
    'no-redirect': boolean(),
    'sidebar': boolean(),
    'sm-cols': number(1),
    'md-cols': number(2),
    'lg-cols': number(3),
    'xl-cols': number(4),
    'xxl-cols': number(4),
  },
  staticProps: {
    mainStyles: STYLES
  },
});

defineCustomComponent({
  component: VehiclesComponent,
  tagName: 'intelimotor-vehicles',
  props: {
    'styles': list(/\s+/, []),
    'app-url': text(),
    'api-url': text(),
    'grid': boolean(),
    'compact-results': boolean(),
    'preview-fit': text(),
    // filters
    'body-types': list('|'),
    'brands': list('|'),
    'models': list('|'),
    'colors': list('|'),
    'locations': list('|'),
    'search': text(),
    'sellers': list('|'),
    'states': list('|'),
    'transmissions': list('|'),
    'min-price': number(null),
    'max-price': number(null),
    'min-kms': number(null),
    'max-kms': number(null),
    'years': list('|'),
    'sm-cols': number(1),
    'md-cols': number(2),
    'lg-cols': number(3),
    'xl-cols': number(4),
    'xxl-cols': number(5),
  },
  staticProps: {
    mainStyles: STYLES
  },
});
