<script>
  import { Chip, Select, Color, Checkbox, OptionsHandler } from 'wind-svelte';

  export let type = '';
  export let options = [];
  export let value = [];
  export let label = '';

  const identify = item => item.name;
  const getText = item => `${item.name} (${item.count})`

  function filter(search, option) {
    if (!search) return true;

    return option.name.toLowerCase().includes(search.toLowerCase());
  }
</script>

{#if type === 'cards'}
  <div class="grid grid-cols-2 gap-2">
    <OptionsHandler multiple {options} {identify} bind:value={value}>
      <svelte:fragment let:option let:selected let:toggle>
        <Chip
          clickable
          active={selected}
          color={Color.isColor(option.extra) ? option.extra : null}
          on:click={toggle}
        >
          {#if typeof option.extra == 'string' && option.extra.startsWith('http')}
            <div class="flex justify-center">
              <div class="relative w-12 h-10 flex flex-column">
                <img
                  src={option.extra}
                  alt={option.name}
                  class="object-contain object-center max-w-full max-h-full"
                />
              </div>
            </div>
          {/if}
          <div class="flex justify-center">
            <div class="truncate">
              {option.name}
            </div>
            <div class="ml-1">
              ({option.count})
            </div>
          </div>
        </Chip>
      </svelte:fragment>
    </OptionsHandler>
  </div>
{:else if type === "checkboxes"}
  <div class="grid grid-cols-2 gap-2">
    <OptionsHandler multiple {options} {identify} bind:value={value}>
      <svelte:fragment let:option let:selected let:toggle>
        <Checkbox
          label={`${option.name} (${option.count})`}
          checked={selected}
          color={option.extra}
          on:click={toggle}
        />
      </svelte:fragment>
    </OptionsHandler>
  </div>
{:else if type === 'autocomplete'}
  <div>
    <Select
      {filter}
      {identify}
      {getText}
      {options}
      {label}
      multiple
      autocomplete
      fullWidth
      bind:value={value}
    >
      <svelte:fragment let:option let:selected>
        <Checkbox checked={selected} label={getText(option)} />
      </svelte:fragment>
    </Select>
  </div>
{/if}
