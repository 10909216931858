<script>
  export let styles = [];
  export let value = false;

  let loadedStyles = []
  $: loadedStyles = new Array(styles.length).fill(false);
  $: value = loadedStyles.every(done => done);
</script>

{#each styles as href, index}
  <link
    rel="stylesheet"
    href={href}
    on:error={() => {
      console.warn(`Intelimotor: Failed to load "${href}"`);
      loadedStyles[index] = true;
      loadedStyles = [...loadedStyles];
    }}
    on:load={() => {
      loadedStyles[index] = true;
      loadedStyles = [...loadedStyles];
    }}
  />
{/each}
