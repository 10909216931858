<script>
  import BodyTypesFilter from './BodyTypesFilter.svelte';
  import BrandsFilter from './BrandsFilter.svelte';
  import ColorsFilter from './ColorsFilter.svelte';
  import KmsFilter from './KmsFilter.svelte';
  import LocationsFilter from './LocationsFilter.svelte';
  import ModelsFilter from './ModelsFilter.svelte';
  import PriceFilter from './PriceFilter.svelte';
  import RedirectButton from './RedirectButton.svelte';
  import SearchFilter from './SearchFilter.svelte';
  import SellerFilter from './SellerFilter.svelte';
  import TransmissionsFilter from './TransmissionsFilter.svelte';
  import VehicleStatesFilter from './VehicleStatesFilter.svelte';
  import YearsFilter from './YearsFilter.svelte';

  export let controlInfo;
</script>

{#if controlInfo.control === 'body-type'}
  <BodyTypesFilter
    type={controlInfo.type}
    label={controlInfo.label || 'Carrocerias'}
  />
{:else if controlInfo.control === 'brand'}
  <BrandsFilter
    type={controlInfo.type}
    label={controlInfo.label || 'Marcas'}
  />
{:else if controlInfo.control === 'color'}
  <ColorsFilter
    type={controlInfo.type}
    label={controlInfo.label || 'Colores'}
  />
{:else if controlInfo.control === 'kms'}
  <KmsFilter
    type={controlInfo.type}
    label={controlInfo.label || 'Kilometraje'}
  />
{:else if controlInfo.control === 'location'}
  <LocationsFilter
    type={controlInfo.type}
    label={controlInfo.label || 'Ubicaciones'}
  />
{:else if controlInfo.control === 'model'}
  <ModelsFilter
    type={controlInfo.type}
    label={controlInfo.label || 'Modelos'}
  />
{:else if controlInfo.control === 'price'}
  <PriceFilter
    type={controlInfo.type}
    label={controlInfo.label || 'Precio'}
  />
{:else if controlInfo.control === 'seller'}
  <SellerFilter
    type={controlInfo.type}
    label={controlInfo.label || 'Agencia'}
  />
{:else if controlInfo.control === 'state'}
  <VehicleStatesFilter
    type={controlInfo.type}
    label={controlInfo.label || 'Estado de vehiculo'}
  />
{:else if controlInfo.control === 'transmission'}
  <TransmissionsFilter
    type={controlInfo.type}
    label={controlInfo.label || 'Transmisiones'}
  />
{:else if controlInfo.control === 'year'}
  <YearsFilter
    type={controlInfo.type}
    label={controlInfo.label || 'Años'}
  />
{:else if controlInfo.control === 'redirect'}
  <RedirectButton
    label={controlInfo.label || 'Ver {count} vehiculos'}
  />
{:else if controlInfo.control === 'search'}
  <SearchFilter
    label={controlInfo.label || 'Buscar'}
  />
{/if}
