<script>
  import { getContext } from 'svelte';
  import urlify from '../utils/urlify';
  import CompactItem from './CompactItem.svelte';
  import ProminentItem from './ProminentItem.svelte';

  export let fullwidth = false;
  export let description = '';
  export let id = '';
  export let kms = '';
  export let location = '';
  export let picture = '';
  export let price = '';
  export let year = '';
  export let seller = '';

  const vehicleContext = getContext('vehicle');

  $: href = `${$vehicleContext.config.appUrl}/auto/${urlify(description)}/${id}`;
  $: objectFit = $vehicleContext.config.previewFit;
</script>

{#if $vehicleContext.config.compactResults}
  <CompactItem
    {fullwidth}
    {description}
    {kms}
    {href}
    {location}
    {picture}
    {price}
    {year}
    seller={$vehicleContext.sellers.length > 1 && seller}
  />
{:else}
  <ProminentItem
    {fullwidth}
    {description}
    {kms}
    {href}
    {location}
    {picture}
    {price}
    {year}
    {objectFit}
    seller={$vehicleContext.sellers.length > 1 && seller}
  />
{/if}
