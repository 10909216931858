<script>
  import { getContext } from 'svelte';
  import { Button } from 'wind-svelte';

  export let label = '';

  const searchContext = getContext('search');

  $: ({ values, config, filters } = $searchContext);

  function onClick() {
    const event = new CustomEvent('search', {
      detail: values,
    });

    window.dispatchEvent(event);
    if (config.noRedirect) return;

    const url = new URL(`${config.appUrl}/buscar`);
    const params = url.searchParams;
    const {
      bodyTypes,
      brands,
      colors,
      locations,
      maxKms,
      maxPrice,
      minKms,
      minPrice,
      models,
      search,
      sellers,
      states,
      transmissions,
      years,
    } = values;

    if (bodyTypes.length) params.set('bodyTypes', bodyTypes.join('–'));
    if (brands.length) params.set('brands', brands.join('–'));
    if (colors.length) params.set('colors', colors.join('–'));
    if (locations.length) params.set('locations', locations.join('–'));
    if (models.length) params.set('models', models.join('–'));
    if (sellers.length) params.set('sellers', sellers.join('–'));
    if (states.length) params.set('states', states.join('–'));
    if (transmissions.length) params.set('transmissions', transmissions.join('–'));
    if (years.length) params.set('years', years.join('–'));

    const minKmsRange = filters.kmsRanges[0].from;
    const maxKmsRange = filters.kmsRanges[filters.kmsRanges.length - 1].to;
    if (minKmsRange !== minKms || maxKmsRange !== maxKms) {
      params.set('kms', `${minKms || 0}-${maxKms || 'Infinity'}`); 
    }

    const minPriceRange = filters.priceRanges[0].from;
    const maxPriceRange = filters.priceRanges[filters.priceRanges.length - 1].to;
    if (minPriceRange !== minPrice || maxPriceRange !== maxPrice) {
      params.set('prices', `${minPrice || 0}-${maxPrice || 'Infinity'}`);
    }

    if (search.trim()) url.pathname = `${url.pathname}/${search}`;
    window.open(url, '_blank');
  }

  $: count = $searchContext.filters.total || '';
  $: formatedLabel = label.replace('{count}', count);
</script>

<div class="flex items-end">
  <Button
    fullWidth
    prominent
    color="primary"
    on:click={onClick}
  >
    {formatedLabel}
  </Button>
</div>
