<script>
  import { getContext } from 'svelte';
  import { Pagination } from 'wind-svelte';
  import gqlFetch from "../utils/gqlFetch";
  import createChecker from '../utils/createChecker';
  import searchVehiclesQuery from '../queries/searchVehiclesQuery';
  import getSellersQuery from '../queries/getSellersQuery';
  import VehiclesRowVariant from "./VehiclesRowVariant.svelte";
  import VehiclesGridVariant from "./VehiclesGridVariant.svelte";

  const vehicleContext = getContext('vehicle');

  // sellers
  const getSellersChecker = createChecker(() => {
    gqlFetch({
      query: getSellersQuery,
      url: $vehicleContext.config.apiUrl,
    }).then(({ data }) => {
      $vehicleContext.sellers = data.getSellers;
    });
  });
  $: getSellersChecker($vehicleContext.config.apiUrl);

  let page = 0;
  let prevPage = page;

  // filters
  $: fieldValues = {
    ...$vehicleContext.values,
    page: page + 1,
  };

  async function fetchVehicles(variables) {
    const { data } = await gqlFetch({
      variables,
      query: searchVehiclesQuery,
      url: $vehicleContext.config.apiUrl,
    });
    
    $vehicleContext.vehicles = data.searchVehicles.items;
    $vehicleContext.pages = data.searchVehicles.pages;
    $vehicleContext.ready = true;
  }

  const fetchVehiclesChecker = createChecker(() => {
    if (page === prevPage) {
      prevPage = page = 0;
      fetchVehicles({
        ...fieldValues,
        page: 1,
      });
    } else {
      prevPage = page;
      fetchVehicles(fieldValues);
    }
  });

  // call if a filter updated
  $: fetchVehiclesChecker(...Object.values(fieldValues));
</script>

<style>
  .vehicles-element { 
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
</style>

{#if !$vehicleContext.config.grid}
  <div class="vehicles-element">
    <VehiclesRowVariant />
  </div>
{:else}
  <div class="vehicles-element">
    <VehiclesGridVariant />
    {#if $vehicleContext.pages > 1}
      <div class="flex justify-center mt-2">
        <Pagination
          pages={$vehicleContext.pages}
          bind:value={page}
        />
      </div>
    {/if}
  </div>
{/if}
