const getFiltersInfoQuery = `
  query(
    $bodyTypes: [String!]
    $brands: [String!]
    $colors: [String!]
    $locations: [String!]
    $maxKms: Int
    $maxPrice: Int
    $minKms: Int
    $minPrice: Int
    $models: [String!]
    $search: String
    $sellers: [String!]
    $states: [String!]
    $transmissions: [String!]
    $years: [String!]
  ) {
    getFiltersInfo(input: {
      bodyTypes: $bodyTypes
      brands: $brands
      colors: $colors
      locations: $locations
      maxKms: $maxKms
      maxPrice: $maxPrice
      minKms: $minKms
      minPrice: $minPrice
      models: $models
      search: $search
      sellers: $sellers
      states: $states
      transmissions: $transmissions
      years: $years
    }) {
      bodyTypes {
        name
        count
        extra
      }
      brands {
        name
        count
      }
      colors {
        name
        count
        extra
      }
      kmsRanges {
        from
        to
        count
      }
      locations {
        name
        count
      }
      models {
        name
        count
      }
      priceRanges {
        from
        to
        count
      }
      sellers {
        name
        count
      }
      states {
        name
        count
      }
      transmissions {
        name
        count
      }
      years {
        name
        count
      }
      total
    }
  }
`;

export default getFiltersInfoQuery;
