function toFixed(value, decimals) {
  if (decimals === 0) return parseInt(value).toString();

  return value.toFixed(decimals);
}

export default function formatNumber(number, config = {}) {
  const { decimals, decimalsSeparator, intSeparator } = {
    decimals: 0,
    intSeparator: ',',
    decimalsSeparator: '.',
    ...config,
  };

  const [intSegment, decimalSegment] = toFixed(number, decimals).split('.');

  const formatedInt = intSegment.replace(/\B(?=(\d{3})+(?!\d))/g, intSeparator);
  const formatedDecimals = decimalSegment ? `${decimalsSeparator}${decimalSegment}` : '';

  return `${formatedInt}${formatedDecimals}`;
}
