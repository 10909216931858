<script>
  import { getContext } from 'svelte';
  import { TextInput } from 'wind-svelte';

  export let label;

  const searchContext = getContext('search');
</script>

<div>
  {#if $searchContext.config.sidebar}
    <div m="b-1">
      {label}
    </div>
  {/if}
  <TextInput
    {label}
    fullWidth
    debounce={600}
    bind:value={$searchContext.values.search}
  />
</div>
