import camelizeAttribute from './camelizeAttribute';

export default function defineWebComponent({
  component,
  tagName,
  props = {},
  staticProps = {},
}) {
  let shadow
  class CustomComponent extends HTMLElement {
    static get observedAttributes() {
      return Object.keys(props);
    }

    constructor(...props) {
      super(...props);
      shadow = this.attachShadow({ mode: 'open' });
    }
    
    attributeChangedCallback(name, oldValue, newValue) {
      if (!this.component) return;

      const propName = camelizeAttribute(name);
      const propValue = props[name](newValue);

      this.component.$$set({ [propName]: propValue });
    }

    connectedCallback() {
      if (typeof window === 'object') {
        const event = new CustomEvent('web-component-mounted', {
          bubbles: true,
          detail: {
            type: tagName
          }
        });
        this.dispatchEvent(event);
      }

      if (this.component) return;
      this.component = new component({
        target: shadow,
        props: {
          ...this.getAttributes(),
          ...staticProps,
          webComponentContainer: this,
          dispatchEvent: (name, detail) => {
            const event = new CustomEvent(name, { bubbles: true, detail });
            this.dispatchEvent(event);
          },
        },
      });
    }
  
    getAttributes() {
      const attributes = Object.entries(props).reduce((accumulator, [attributeName, attributeParser]) => {
        const attribute = this.attributes.getNamedItem(attributeName);

        const propName = camelizeAttribute(attributeName);
        const propValue = attributeParser(attribute ? attribute.value : undefined);

        return {
          ...accumulator,
          [propName]: propValue,
        };
      }, {});

      return attributes;
    }
  }

  customElements.define(tagName, CustomComponent);

  return CustomComponent;
}
