<script>
  import { getContext } from 'svelte';
  import OptionsInput from './OptionsInput.svelte';

  export let type;
  export let label;

  const searchContext = getContext('search');
</script>

<div>
  {#if $searchContext.config.sidebar}
    <div m="b-1">
      {label}
    </div>
  {/if}
  <OptionsInput
    {type}
    {label}
    options={$searchContext.filters.models}
    bind:value={$searchContext.values.models}
  />
</div>
