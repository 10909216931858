<script>
  import { getContext } from 'svelte';
  import formatNumber from '../utils/formatNumber';
  import RangeInput from './RangeInput.svelte';

  export let type;
  export let label;

  const searchContext = getContext('search');
</script>

<div>
  {#if $searchContext.config.sidebar}
    <div m="b-1">
      {label}
    </div>
  {/if}
  <RangeInput
    {type}
    {label}
    ranges={$searchContext.filters.priceRanges}
    bind:from={$searchContext.values.minPrice}
    bind:to={$searchContext.values.maxPrice}
  >
    <span slot="selection-begin" let:value>
      ${formatNumber(value || 0)}
    </span>
    <span slot="selection-end" let:value>
      ${formatNumber(value || 0)}
    </span>
  </RangeInput>
</div>
