<script>
  import formatNumber from '../utils/formatNumber';

  export let fullwidth = false;
  export let description = '';
  export let href = '';
  export let kms = '';
  export let location = '';
  export let picture = '';
  export let price = '';
  export let seller = '';
  export let year = '';
</script>

<style>
  .text-shadow {
    text-shadow: '1px 1px 1px rgb(0 0 0 / 50%)',
  }
  .normal-height {
    height: 20rem;
  }
  .higher {
    height: 21rem;
  }
</style>

<a
  class="
    vehicle-item
    cursor-pointer
    relative
    block
    {fullwidth ? 'w-full' : 'w-80'}
    {seller ? 'normal-height' : 'higher'}
  "
  href={href}
>
  <img
    class="absolute w-full h-full object-cover"
    src={picture}
    alt={description}
  />
  <div class="absolute w-full h-full flex flex-col justify-end">
    <div class="p-2 text-white bg-black bg-opacity-25 text-shadow">
      <div class="mb-1 text-xl">
        {`$${formatNumber(price)}`}
      </div>
      <div>
        {`${year} | ${formatNumber(kms)} km | ${location}`}
      </div>
      <div>
        {description}
      </div>
      {#if seller}
        <div>
          {seller}
        </div>
      {/if}
    </div>
  </div>
</a>
