const searchVehiclesQuery = `
  query(
    $bodyTypes: [String!]
    $brands: [String!]
    $colors: [String!]
    $maxPrice: Int
    $maxKms: Int
    $locations: [String!]
    $minPrice: Int
    $minKms: Int
    $models: [String!]
    $search: String
    $sellers: [String!]
    $states: [String!]
    $transmissions: [String!]
    $page: Int
    $years: [String!]
  ) {
    searchVehicles(input: {
      bodyTypes: $bodyTypes
      brands: $brands
      colors: $colors
      locations: $locations
      minKms: $minKms
      maxKms: $maxKms
      maxPrice: $maxPrice
      minPrice: $minPrice
      models: $models
      page: $page
      sellers: $sellers
      search: $search
      states: $states
      transmissions: $transmissions
      years: $years
    }) {
      pages
      items {
        description
        id
        kms
        location
        picture
        price
        seller
        year
      }
    }
  }
`;

export default searchVehiclesQuery;
