<script>
  import { getContext } from 'svelte';
  import { Card, Grid } from 'wind-svelte';
  import gqlFetch from '../utils/gqlFetch';
  import getFiltersInfoQuery from '../queries/getFiltersInfoQuery';
  import createChecker from '../utils/createChecker';
  import DynamicControl from './DynamicControl.svelte';

  const searchContext = getContext('search');

  const getFiltersChecker = createChecker(async () => {
    const { data } = await gqlFetch({
      query: getFiltersInfoQuery,
      url: $searchContext.config.apiUrl,
      variables: $searchContext.values,
    });
    $searchContext.filters = data.getFiltersInfo;

    const { priceRanges, kmsRanges } = data.getFiltersInfo;

    // select all if no selection
    if ($searchContext.values.minPrice === null) {
      $searchContext.values.minPrice = priceRanges[0].from;
    }
    if ($searchContext.values.maxPrice === null) {
      $searchContext.values.maxPrice = priceRanges[priceRanges.length - 1].to;
    }

    if ($searchContext.values.minKms === null) {
      $searchContext.values.minKms = kmsRanges[0].from;
    }
    if ($searchContext.values.maxKms === null) {
      $searchContext.values.maxKms = kmsRanges[kmsRanges.length - 1].to;
    }
  });

  // refetch filters info
  $: getFiltersChecker($searchContext.config.apiUrl, ...Object.values($searchContext.values));

  const changedEventChecker = createChecker(() => {
    const event = new CustomEvent('filtersChanged', {
      detail: $searchContext.values,
    });

    window.dispatchEvent(event); 
  }, ...Object.values($searchContext.values));

  $: changedEventChecker(...Object.values($searchContext.values));
  $: ({ smCols, mdCols, lgCols, xlCols, xxlCols, sidebar } = $searchContext.config);
</script>

<style>
  .root {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
</style>

<div class="root inline-block {$searchContext.config.sidebar ? 'w-72' : 'w-full'}">
  <Card fullWidth>
    <Grid
      smCols={sidebar ? 1 : smCols}
      mdCols={sidebar ? 1 : mdCols}
      lgCols={sidebar ? 1 : lgCols}
      xlCols={sidebar ? 1 : xlCols}
      xxlCols={sidebar ? 1 : xxlCols}
      spacing={0.75}
    >
      {#each $searchContext.config.controls as controlInfo}
        <DynamicControl {controlInfo} />
      {/each}
    </Grid>
  </Card>
</div>
