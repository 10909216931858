<script>
  import { getColor } from 'wind-svelte';
  import formatNumber from '../utils/formatNumber';

  export let fullwidth = false;
  export let description = '';
  export let href = '';
  export let kms = '';
  export let location = '';
  export let picture = '';
  export let price = '';
  export let seller = '';
  export let year = '';
  export let objectFit = '';

  const pricingColor = getColor('pricing');
</script>

<style>
  .container-shadow {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  }
  .text-shadow {
    text-shadow: '1px 1px 1px rgb(0 0 0 / 50%)',
  }
  .normal-height {
    height: 24rem;
  }
  .higher {
    height: 25rem; 
  }
</style>

<a
  style="{$pricingColor.themeVars()}"
  class="
    vehicle-item
    cursor-pointer
    relative
    overflow-hidden
    flex flex-col
    container-shadow
    no-underline
    rounded-lg
    bg-white
    {fullwidth ? 'w-full' : 'w-80'}
    {seller ? 'normal-height' : 'higher'}
  "
  href={href}
>
  <img
    style="
      {objectFit ? `object-fit: ${objectFit};` : ''}
    "
    class="w-full h-64 object-cover"
    src={picture}
    alt={description}
  />
  <div class="flex flex-col justify-end">
    <div class="p-2 text-shadow">
      <div class="mb-1 text-2xl font-medium text-color">
        {`$${formatNumber(price)}`}
      </div>
      <div class="text-black">
        {description}
      </div>
      <div class="text-gray-500">
        {`${year} | ${formatNumber(kms)} km | ${location}`}
      </div>
      {#if seller}
        <div class="text-gray-500">
          {seller}
        </div>
      {/if}
    </div>
  </div>
</a>
