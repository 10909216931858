<script>
  import { getContext } from 'svelte';
  import { CircularProgress } from 'wind-svelte';
  import { scrollToNextChild, scrollToPrevChild } from '../utils/horiztonalScroll';
  import VehicleItem from "./VehicleItem.svelte";
  import SideButton from "./SideButton.svelte";

  const vehicleContext = getContext('vehicle');

  let sliderElement = null;

  function next() {
    scrollToNextChild(sliderElement);
  }

  function prev() {
    scrollToPrevChild(sliderElement);
  }
</script>

<style>
  nav {
    scroll-behavior: smooth;
  }

  .blur {
    filter: blur(5px);
  }
</style>

<section class="relative">
  <div class:blur={!$vehicleContext.ready} class="h-full min-h-80">
    <SideButton left on:click={prev} />
      <nav
        class="overflow-x-auto flex p-1"
        bind:this={sliderElement}
      >
        {#each $vehicleContext.vehicles as vehicle, index}
          <div class:pl-2={index > 0}>
            <VehicleItem
              description={vehicle.description}
              id={vehicle.id}
              kms={vehicle.kms}
              location={vehicle.location}
              picture={vehicle.picture}
              price={vehicle.price}
              seller={vehicle.seller}
              year={vehicle.year}
            />
          </div>
        {/each}
      </nav>
    <SideButton right on:click={next} />
  </div>
  {#if !$vehicleContext.ready}
    <div class="absolute inset-0 flex items-center justify-center">
      <CircularProgress size={5} />
    </div>
  {/if}
</section>
