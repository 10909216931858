export function scrollToNextChild(container) {
  function getLastVisibleElement(element = container.children[0]) {
    if (!element) return null;

    const endOfSlider = container.offsetWidth + container.scrollLeft;
    const endOfElement = element.offsetWidth + element.offsetLeft;

    if (endOfElement > endOfSlider) {
      return element;
    }
    if (!element.nextElementSibling) {
      return null;
    }

    return getLastVisibleElement(element.nextElementSibling);
  }

  const lasVisibleElement = getLastVisibleElement();
  if (!lasVisibleElement) return;

  const endOfElement = lasVisibleElement.offsetLeft + lasVisibleElement.offsetWidth;
  container.scrollLeft = endOfElement - container.offsetWidth;
}

export function scrollToPrevChild(container) {
  function getFirstVisibleElement(element = container.children[0]) {
    if (!element) return null;

    const beginOfSlider = container.scrollLeft;
    const endOfElement = element.offsetWidth + element.offsetLeft;

    if (endOfElement >= beginOfSlider) {
      return element
    }
    if (!element.nextElementSibling) {
      return null;
    }

    return getFirstVisibleElement(element.nextElementSibling);
  }

  const firstVisibleElement = getFirstVisibleElement();
  if (!firstVisibleElement) return;

  container.scrollLeft = firstVisibleElement.offsetLeft;
}