<script>
  import ChevronLeft from 'svelte-material-icons/ChevronLeft.svelte';
  import ChevronRight from 'svelte-material-icons/ChevronRight.svelte';

  export let left = false;
  export let right = false;
</script>

<button
  class="
    slide-button
    cursor-pointer
    absolute
    top-1/2
    transform -translate-y-1/2
    rounded-full
    z-1
    w-10 h-10
    bg-black bg-opacity-50
    text-white
    flex items-center justify-center
    border-0
  "
  class:left-0={left}
  class:right-0={right}
  on:click
>
  {#if left}
    <ChevronLeft size="2rem" />
  {:else}
    <ChevronRight size="2rem" />
  {/if}
</button>
