<script>
  import { setContext } from 'svelte';
  import { writable } from 'svelte/store';
  import { Theme } from 'wind-svelte';
  import ExternalStyles from '../components/ExternalStyles.svelte';
  import Search from './Search.svelte';

  // config
  export let appUrl = '';
  export let apiUrl = '';
  export let controls = [];
  export let noRedirect = false;
  export let sidebar = false;
  export let smCols = 1;
  export let mdCols = 1;
  export let lgCols = 1;
  export let xlCols = 1;
  export let xxlCols = 1;

  // others
  export let styles = [];
  export let mainStyles = '';
  export let webComponentContainer = null;
  export let dispatchEvent = null;
  export let primaryColor = null;

  setContext('webComponent', { webComponentContainer, dispatchEvent });

  const searchContext = writable({
    config: {
      appUrl: null,
      apiUrl: null,
      controls: null,
      noRedirect: null,
      sidebar: null,
      smCols: null,
      mdCols: null,
      lgCols: null,
      xlCols: null,
      xxlCols: null,
    },
    filters: {
      bodyTypes: [],
      brands: [],
      colors: [],
      kmsRanges: [],
      locations: [],
      models: [],
      priceRanges: [],
      sellers: [],
      states: [],
      transmissions: [],
      years: [],
      total: null,
    },
    values: {
      bodyTypes: [],
      brands: [],
      colors: [],
      locations: [],
      minKms: null,
      maxKms: null,
      minPrice: null,
      maxPrice: null,
      models: [],
      search: '',
      sellers: [],
      states: [],
      transmissions: [],
      years: [],
    },
  });

  setContext('search', searchContext);

  $: $searchContext.config.appUrl = appUrl;
  $: $searchContext.config.apiUrl = apiUrl;
  $: $searchContext.config.controls = controls;
  $: $searchContext.config.noRedirect = noRedirect;
  $: $searchContext.config.sidebar = sidebar;
  $: $searchContext.config.smCols = smCols;
  $: $searchContext.config.mdCols = mdCols;
  $: $searchContext.config.lgCols = lgCols;
  $: $searchContext.config.xlCols = xlCols;
  $: $searchContext.config.xxlCols = xxlCols;

  let loaded = false;
  let stylesLoaded = false;
</script>

<link rel="stylesheet" href={mainStyles} on:load={() => loaded = true}>
<ExternalStyles styles={styles} bind:value={stylesLoaded} />

<Theme colors={{ primary: primaryColor }}>
  {#if loaded && stylesLoaded}
    <Search />
  {/if}
</Theme>
