<script>
  import { getContext } from 'svelte';
  import { Grid, CircularProgress } from 'wind-svelte';
  import VehicleItem from "./VehicleItem.svelte";

  const vehicleContext = getContext('vehicle');

  $: isCompact = $vehicleContext.config.compactResults;
  $: ({ smCols, mdCols, lgCols, xlCols, xxlCols } = $vehicleContext.config);
</script>

<style>
  .blur {
    filter: blur(5px);
  }
</style>

<section class="
  relative
  min-h-32
  {isCompact ? 'gap-2' : 'gap-6'}
  {$vehicleContext.ready ? '' : 'blur'}
">
  <div class="{$vehicleContext.ready ? '' : 'blur'}">
    <Grid
      {smCols}
      {mdCols}
      {lgCols}
      {xlCols}
      {xxlCols}
      spacing={isCompact ? 0.5 : 1.5}
    >
      {#each $vehicleContext.vehicles as vehicle (vehicle.id)}
        <VehicleItem
          fullwidth
          description={vehicle.description}
          id={vehicle.id}
          kms={vehicle.kms}
          location={vehicle.location}
          picture={vehicle.picture}
          price={vehicle.price}
          seller={vehicle.seller}
          year={vehicle.year}
        />
      {/each}
    </Grid>
  </div>
  {#if !$vehicleContext.ready}
    <div class="absolute left-1/2 top-0 transform -translate-x-1/2">
      <CircularProgress size={5} />
    </div>
  {/if}
</section>
